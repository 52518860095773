import React from 'react';

export default class DiscountList extends React.Component {

  createOnRemoveDiscount(discount) {
    return () => {
      this.props.onRemoveDiscount(discount);
    };
  }

  render() {
    var discounts = this.props.discounts;

    discounts = discounts.map((discount) => {
      return (
        <tr key={discount.id}>
          <td><a href={'discounts/' + discount.id + '/edit'}>{discount.name}</a></td>
          <td>{discount.discountable_type}</td>
          <td>{discount.shipping_method}</td>
          <td>
            <button className='btn btn-danger' onClick={this.createOnRemoveDiscount(discount)}>
              Löschen
            </button>
          </td>
        </tr>
      );
    });

    return (<div>
      <table className='table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Typ</th>
            <th>Lieferart</th>
          </tr>
        </thead>
        <tbody>
          {discounts}
        </tbody>
      </table>

      <hr />

      <button className='btn btn-success' onClick={this.props.onCreateDiscount}>Neuer Rabatt</button>
    </div>);

  }

}

window.DiscountList = DiscountList;
