$(document).ready(() => {
  window.MessageBus.start();

  window.MessageBus.subscribe(
    '/analysis',
    (message) => {
      $('#analysis-container').html(message.text);
    },
    window.lastAnalysisID,
  );
});
