import $ from "jquery";
import "webpack-jquery-ui/datepicker";
import "webpack-jquery-ui/sortable";

// import "webpack-jquery-ui/disable-selection";

/* German initialisation for the jQuery UI date picker plugin. */
/* Written by Milian Wolff (mail@milianw.de). */
$.datepicker.regional['de'] = {
	closeText: 'schließen',
	prevText: '&#x3c;zurück',
	nextText: 'Vor&#x3e;',
	currentText: 'heute',
	monthNames: ['Januar','Februar','März','April','Mai','Juni',
	'Juli','August','September','Oktober','November','Dezember'],
	monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun',
	'Jul','Aug','Sep','Okt','Nov','Dez'],
	dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
	dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
	dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
	weekHeader: 'Wo',
	dateFormat: 'dd.mm.yy',
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: ''};
$.datepicker.setDefaults($.datepicker.regional['de']);



var pageInit = function() {
	// if(/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
	// 	window.$('.jdatepicker').datepicker({
	// 	  showAnim: "slideDown",
	// 	  dateFormat: 'dd.mm.yy'
	// 	});
	// }
	// else {
		$('.jdatepicker').pickdate({
			monthsFull: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
			monthsShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
			weekdaysFull: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
			weekdaysShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
			cancel: 'Abbrechen',
			selectYears: true,
			format: 'dd.mm.yyyy',
			firstDay: 1
		});
	// }

  // $('input:submit').button();
  $('#product-table a').button();
  // $('#jqbutton a').button();
  $('.buttonclass').button();
  // $('a').click(function() {
  //   activityIndicator();
  // });
  $('form.activity-indicator').submit( function() {
    activityIndicator();
  });

  $(".sortable").sortable();
  $(".sortable").disableSelection();

  // AJAX Requests-Links handeln (Links in <div class="ajax-links"> fassen)..
  // ... mit Progress-Wheel ..
  // $('.ajax-progress-links a').live("click", function () {
  //   $(this).activity({
  //     length: 3,
  //     width: 2,
  //     speed: 0.8,
  //     space: 3,
  //     color: "#000"
  //   });
  //   $.get(this.href, null, null, 'script');
  //   return false;
  // });
  // // ... ohne Progress-Wheel ..
  // $('.ajax-links a').live("click", function () {
  //   $.get(this.href, null, null, 'script');
  //   return false;
  // });

  // Bootstrap Popovers und Tooltips mit data-hinttype auslösen
  $('*[data-hinttype="popover"]').popover();
  $('*[data-hinttype="tooltip"]').tooltip();
};


function activityIndicator() {
  $('#activitybox').dialog({
    modal: true,
    bgiframe: true,
    resizable: false,
    draggable: false,
    height:140,
    width: 20
  });
  $('.ui-dialog-titlebar').hide();
  $('div').removeClass('ui-widget-content')
  $('#activitybox').activity({
    length: 12,
    width: 8,
    speed: 0.8,
    space: 5
  });
}

$(document).ready(pageInit);
