import React from 'react';
import Immutable from 'immutable';

import DiscountList from './DiscountList';

export default class DiscountListContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      discounts: Immutable.fromJS(this.props.discounts),
      message: null
    };
  }

  onCreateDiscount() {
    $.post(this.props.new_discounts_path + '.json', { }, (data) => {
      const newDiscounts = this.state.discounts.push(Immutable.fromJS(data.discount));

      this.setState({
          discounts: newDiscounts
      });
    });
  }

  onRemoveDiscount(discount) {
    $.ajax({
      url: document.location.href + '/' + discount.id,
      type: 'DELETE',
      success: (result) => {
        const newDiscounts = this.state.discounts.filter((d) => discount.id != d.get('id'))
        this.setState({
          discounts: newDiscounts,
          message: 'Erfolgreich gelöscht'
        });
      },
      error: (result) => {
        console.log(result);
      }
    });
  }

  onHideSave() {
    this.setState({
      message: null
    });
  }

  render() {
    let dialog = null;
    if(this.state.message) {
      dialog = (
        <SweetAlert success title="Nachricht" onConfirm={this.onHideSave.bind(this)}>
          {this.state.message}
        </SweetAlert>
      );
    }

    return (
      <div>
        {dialog}
        <DiscountList
          discounts={this.state.discounts.toJS()}
          onCreateDiscount={this.onCreateDiscount.bind(this)}
          onRemoveDiscount={this.onRemoveDiscount.bind(this)}
        />
      </div>
    );
  }

}

window.DiscountListContainer = DiscountListContainer;
