import React from 'react';
import Immutable from 'immutable';

export default class CsvUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: Immutable.List(),
    };
  }

  componentDidMount() {
    MessageBus.start();

    window.MessageBus.subscribe(
      '/csv_import',
      (message) => {
        this.setState({
          messages: this.state.messages.push(message),
        });
      },
      window.lastImportMessageId,
    );
  }

  render() {
    let messages = this.state.messages.map((message, i) => {
      return <div key={i} dangerouslySetInnerHTML={{__html: message}} />;
    });
    return <div>{messages}</div>;
  }
}

window.CsvUpload = CsvUpload;
