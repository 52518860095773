import React from 'react';

import FullCalendar from 'fullcalendar-reactwrapper';

export default class ZbonCalendar extends React.Component {
  render() {
    return (
      <div>
        <FullCalendar {...this.props} />
      </div>
    );
  }
}
