import React from 'react';

const getCategorySizes = (category) => {
  return category.get('products').map((product) => {
    return product.get('size_names');
  }).flatten().toSet().toList();
}

export default class DiscountCategory extends React.Component {

  findProductCategory() {
    return this.props.productVersion.get('product_categories').
      find((c) => c.get('name') === this.props.discountCategory.get('category_name'));
  }

  isIncludedProduct(product) {
    return this.props.discountCategory.get('excluded_products')
      .indexOf(product.get('sku')) === -1;
  }

  removeSize(size) {
    const sizes = this.props.discountCategory.get('sizes').filter((s) => {
      return s !== size;
    });
    this.props.onCategoryChanged(
      this.props.discountCategory.set('sizes', sizes)
    );
  }

  addSize(size) {
    const newSizes = this.props.discountCategory.get('sizes').push(size);

    this.props.onCategoryChanged(
      this.props.discountCategory.set('sizes', newSizes)
    );
  }

  onSizeChanged(size, event) {
    if(event.currentTarget.checked) {
      this.addSize(size);
    }
    else {
      this.removeSize(size);
    }
  }

  removeProduct(product) {
    const newExcludedProducts = this.props.discountCategory.get('excluded_products')
      .push(product.get('sku'));

    this.props.onCategoryChanged(
      this.props.discountCategory.set('excluded_products', newExcludedProducts)
    );
  }

  addProduct(product) {
    const products = this.props.discountCategory.get('excluded_products').filter((sku) => {
      return sku !== product.get('sku');
    });
    this.props.onCategoryChanged(
      this.props.discountCategory.set('excluded_products', products)
    );
  }

  onProductChanged(product, event) {
    if(event.currentTarget.checked) {
      this.addProduct(product);
    }
    else {
      this.removeProduct(product);
    }
  }

  render() {
    var productCategory = this.findProductCategory();
    var sizes = getCategorySizes(productCategory);

    var sizes = sizes.map((size) => {
      return (
          <label className="checkbox inline" style={{ marginRight: 10 }} key={size}>
            <input type="checkbox"
                   onChange={this.onSizeChanged.bind(this, size)}
                   checked={this.props.discountCategory.get('sizes').indexOf(size) !== -1} />
            {size}
          </label>
      );
    });

    var products = productCategory.get('products').map((product) => {
      return (
        <div className='col-md-12' key={product.get('sku')}>
          <label className="checkbox">
            <input type="checkbox"
                   checked={this.isIncludedProduct(product)}
                   onChange={this.onProductChanged.bind(this, product)} />
            { product.get('name') }
          </label>
        </div>
      );
    });

    return (
      <div>
        <form className='form-inline'>
          <div className="controls closer">
            {sizes}
          </div>
        </form>
        <hr />
        <form>
          <div className="controls closer row">
            {products}
          </div>
        </form>
      </div>
    );
  }
}
