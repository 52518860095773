import React from 'react'

export default class DiscountShipping extends React.Component {
  onShippingChanged (e) {
    const changedDiscount = this.props.discount.set(
      'shipping_method',
      parseInt(e.currentTarget.value)
    )
    this.props.onDiscountChanged(changedDiscount)
  }

  render () {
    const discount = this.props.discount

    if (!discount) {
      return <div>Kein Rabatt geladen</div>
    }

    return (
      <div className='row'>
        <div className='col-md-3'>
          <form>
            <div className='controls closer row'>
              <div className='col-md-12'>
                <label className='radio'>
                  <input
                    type='radio'
                    name='shipping_method'
                    value='0'
                    onChange={this.onShippingChanged.bind(this)}
                    checked={discount.get('shipping_method') === 0}
                  />
                  Alle
                </label>
              </div>
              <div className='col-md-12'>
                <label className='radio'>
                  <input
                    type='radio'
                    name='shipping_method'
                    value='1'
                    onChange={this.onShippingChanged.bind(this)}
                    checked={discount.get('shipping_method') === 1}
                  />
                  Restaurant
                </label>
              </div>
              <div className='col-md-12'>
                <label className='radio'>
                  <input
                    type='radio'
                    name='shipping_method'
                    value='3'
                    onChange={this.onShippingChanged.bind(this)}
                    checked={discount.get('shipping_method') === 3}
                  />
                  Lieferservice
                </label>
              </div>
              <div className='col-md-12'>
                <label className='radio'>
                  <input
                    type='radio'
                    name='shipping_method'
                    value='2'
                    onChange={this.onShippingChanged.bind(this)}
                    checked={discount.get('shipping_method') === 2}
                  />
                  Selbstabholer
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
