import Immutable from 'immutable';
import React from 'react';

const tableStyle = Immutable.fromJS({
  position: 'absolute',
  display: 'block',
  backgroundImage: 'url(/images/Tisch240_A.svg)',
  backgroundSize: '100% 100%',
  color: 'white',
  textAlign: 'center'
});

export default class RoomTable extends React.Component {

  computedStyle() {
    const top = (this.props.locy * this.props.pixelfactor) - ((this.props.tble_type.height * this.props.pixelfactor) / 2);
    const left = (this.props.locx * this.props.pixelfactor) - ((this.props.tble_type.width * this.props.pixelfactor) / 2);

    return Immutable.fromJS({
      width: this.props.tble_type.width * this.props.pixelfactor,
      height: this.props.tble_type.height * this.props.pixelfactor,
      backgroundImage: 'url(/images/gen/uploads/' + this.props.tble_type.symbolfile + ')',
      top: top,
      left: left,
      transform: 'rotate(' + this.props.orientation + 'deg)'
    });
  }

  onTableClicked() {
    this.props.onTableClicked(this.props.table);
  }

  render() {
    const thisTableStyle = tableStyle.merge(this.computedStyle());

    return (
      <div onClick={this.onTableClicked.bind(this)} className='table' style={thisTableStyle.toJS()} data-id={this.props.id}>
        {this.props.name}
      </div>
    );
  }
}
