import React from 'react';
import Immutable from 'immutable';

export default class ImportCustomers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      db_progress: 0,
      parse_progress: 0,
      messages: Immutable.List(),
      download_link: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.db_progress !== nextState.db_progress) {
      return true;
    }

    if (this.state.parse_progress !== nextState.parse_progress) {
      return true;
    }

    if (this.state.messages !== nextState.messages) {
      return true;
    }

    if (this.state.download_link !== nextState.download_link) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    window.MessageBus.subscribe('/customer-import', (data) => {
      if ('parse_progress' in data) {
        this.setState({
          parse_progress: Math.round(data.parse_progress),
        });
      } else if ('db_progress' in data) {
        this.setState({
          db_progress: Math.round(data.db_progress),
        });
      } else if ('done' in data) {
        let new_messages = this.state.messages.push(data.message);
        this.setState({
          messages: new_messages,
        });
        alert(data.message);
      } else if ('download_link' in data) {
        this.setState({
          download_link: data.download_link,
        });
      } else {
        let new_messages = this.state.messages.push(data.message);
        this.setState({
          messages: new_messages,
        });
      }
    });
  }

  render() {
    let messages = this.state.messages.map((message, index) => (
      <li key={index}>{message}</li>
    ));
    let download_button = null;
    if (this.state.download_link) {
      download_button = (
        <a className="btn btn-success" href={this.state.download_link}>
          CSV Download
        </a>
      );
    }

    return (
      <div>
        <ul>{messages}</ul>

        <div className="row">
          <div className="col-md-3">
            <h4>Kunden lesen:</h4>
          </div>
          <div className="col-md-9">
            <div className="progress">
              <div
                className="progress-bar progress-bar-success"
                style={{width: this.state.parse_progress + '%'}}>
                {this.state.parse_progress}%
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <h4>Kunden schreiben:</h4>
          </div>
          <div className="col-md-9">
            <div className="progress">
              <div
                className="progress-bar progress-bar-success"
                style={{width: this.state.db_progress + '%'}}>
                {this.state.db_progress}%
              </div>
            </div>
          </div>
        </div>
        {download_button}
      </div>
    );
  }
}

window.ImportCustomers = ImportCustomers;
