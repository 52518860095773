import React from 'react'

export default class DiscountConditions extends React.Component {
  onMinOrderChanged (e) {
    const newMinOrderValue = e.currentTarget.value
      .replace(',', '.')
      .replace(/[^0-9.]+/g, '')
      .replace(/(\.\d\d)\d+/g, '$1')
      .replace(/^\./g, '0.')

    const newDiscount = this.props.discount.set(
      'min_order_value',
      newMinOrderValue
    )
    this.props.onDiscountChanged(newDiscount)
  }

  onShippingIncludeChanged (e) {
    const newDiscount = this.props.discount.set(
      'min_order_include_shipping',
      e.currentTarget.checked
    )
    this.props.onDiscountChanged(newDiscount)
  }

  render () {
    const discount = this.props.discount

    return (
      <div className='panel panel-default'>
        <div className='panel-heading'>
          <h4>Bedingungen</h4>
        </div>
        <div className='panel-body'>
          <div className='row'>
            <div className='col-md-1 col-md-offset-2'>MBW:</div>
            <div className='col-md-2'>
              <input
                type='text'
                value={discount.get('min_order_value')}
                onChange={this.onMinOrderChanged.bind(this)}
                className='col-md-12'
              />
            </div>
            <div className='col-md-6'>
              <label className='checkbox closer col-md-12 inline-label'>
                <input
                  type='checkbox'
                  onChange={this.onShippingIncludeChanged.bind(this)}
                  checked={discount.get('min_order_include_shipping')}
                />
                inkl. Lieferservice
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
