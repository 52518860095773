import React from 'react';
import Immutable from 'immutable';

const getCategorySizes = (category) => {
  return category.get('products').map((product) => {
    return product.get('size_names');
  }).flatten().toSet().toList();
}

export default class DiscountCategories extends React.Component {

  isCategoryChecked(category) {
    var result = this.props.discount.get('discount_categories').
      filter((c) => c.get('category_name') === category.get('name'));

    return result.size > 0;
  }

  toggleCategory(category, e) {
    if(e.currentTarget.checked) {
      this.addCategory(category);
    }
    else {
      this.removeCategory(category);
    }
  }

  createDiscountCategory(category) {
    return Immutable.Map({
      category_name: category.get('name'),
      excluded_products: [],
      sizes: getCategorySizes(category)
    });
  }

  addCategory(category) {
    const discountCategory = this.createDiscountCategory(category);
    const discountCategories = this.props.discount.get('discount_categories');
    const newDiscountCategories = discountCategories.push(discountCategory);
    console.log(newDiscountCategories.toJS());

    this.props.onDiscountChanged(
      this.props.discount.set('discount_categories', newDiscountCategories)
    );
  }

  removeCategory(category) {
    // console.log(category.toJS());
    // console.log(this.props.discount.get('discount_categories').toJS());
    const categories = this.props.discount.get('discount_categories')
      .filter((discount_category) => discount_category.get('category_name') != category.get('name'));

    this.props.onDiscountChanged(
        this.props.discount.set('discount_categories', categories)
    );
  }

  onMarkAll() {
    const categories = this.props.productVersion.get('product_categories').map(this.createDiscountCategory);

    this.props.onDiscountChanged(
        this.props.discount.set('discount_categories', categories)
    );
  }

  render() {
    var categories = this.props.productVersion.get('product_categories').map((category) => {
      return (
        <div className='col-md-12' key={category.get('name')}>
          <label className="checkbox">
            <input type="checkbox" checked={this.isCategoryChecked(category)} onChange={this.toggleCategory.bind(this, category)} />
            { category.get('name') }
          </label>
        </div>
      );
    });

    return (<div>
      <div className="row">
        <div className="col-md-8">
          <button className="btn" onClick={this.onMarkAll.bind(this)}>Alle Markieren</button>
          <form id="categories">
            <div className="controls closer row">
              {categories}
            </div>
          </form>
        </div>
      </div>
    </div>);
  }
}
