import React from 'react'
import Immutable from 'immutable'

import DiscountEditor from './DiscountEditor'

export default function DiscountEditorContainer (props) {
  const [discount, setDiscount] = React.useState(
    Immutable.fromJS(props.discount)
  )

  const updateDiscount = React.useCallback(async function(event) {
    let updatedDiscount = discount
      .delete('product_version_id')
      .set(
        'discount_categories_attributes',
        discount.get('discount_categories')
      )
      .delete('discount_categories')

    const attributes = updatedDiscount
      .get('discount_categories_attributes')
      .map((discountCategory) => {
        return Immutable.Map({
          category_name: discountCategory.get('category_name'),
          excluded_products: discountCategory.get('excluded_products'),
          sizes: discountCategory.get('sizes')
        })
      })

    updatedDiscount = updatedDiscount.set(
      'discount_categories_attributes',
      attributes
    )

    console.log(discount.toJS())
    $.ajax({
      url: props.update_url,
      data: {
        discount: updatedDiscount.toJS()
      },
      method: 'PUT'
    }).done((data) => {
      alert('Speichern erfolgreich')
    })
  }, [discount])

  return (
    <DiscountEditor
      productVersion={Immutable.fromJS(props.product_version)}
      discount={discount}
      onUpdateDiscount={updateDiscount}
      onDiscountChanged={setDiscount}
    />
  )
}

window.DiscountEditorContainer = DiscountEditorContainer
