// import React from 'react';
// import Immutable from 'immutable';
// import moment from 'moment';
//
// moment.locale('de');
//
// const createOpeningTimesTable = (opening_times) => {
//   let data = Immutable.Map({});
//   for(let i=1; i<=7; i++) {
//     data = data.set(i, Immutable.fromJS({
//       enabled: false,
//       opening_times: []
//     }));
//   }
//
//   opening_times.forEach((opening_time) => {
//     let currentOpeningTimeData = data.get(opening_time.get('day'));
//     let assignedOpeningTimes = currentOpeningTimeData.get('opening_times');
//     currentOpeningTimeData = currentOpeningTimeData.set('enabled', true);
//     currentOpeningTimeData = currentOpeningTimeData.set('opening_times', assignedOpeningTimes.push(opening_time));
//
//     data = data.set(opening_time.get('day'), currentOpeningTimeData);
//
//     return true;
//   });
//
//   return data;
// }
//
// const OpeningTimesTabs = (props) => {
//   return (
//     <ul className="nav nav-tabs" role="tablist">
//       <li role="presentation" className="nav-item">
//   			<a href="#inhouse"  className='nav-link active' aria-controls="home" role="tab" data-toggle="tab">
//           { props.opening_times_restaurant_label }
//   			</a>
//   		</li>
//       <li className='nav-item' role="presentation">
//   			<a className='nav-link' href="#delivery" aria-controls="profile" role="tab" data-toggle="tab">
//           { props.opening_times_delivery_label }
//         </a>
//   		</li>
//       <li className='nav-item' role="presentation">
//   			<a className='nav-link' href="#booking" aria-controls="profile" role="tab" data-toggle="tab">
//           Reservierung
//   			</a>
//   		</li>
//     </ul>
//   );
// }
//
// const DAY_MAPPING = {
//   '1': 'Montag',
//   '2': 'Dienstag',
//   '3': 'Mittwoch',
//   '4': 'Donnerstag',
//   '5': 'Freitag',
//   '6': 'Samstag',
//   '7': 'Sonntag'
// };
//
// class OpeningTimesTable extends React.Component {
//
//   onInputOpenChanged(event, openingTime, day) {
//     event.preventDefault();
//
//     openingTime = openingTime.set('open_time', event.target.value);
//
//     const dayOpeningTimes = this.props.openingTimes.get(day);
//     this.props.onChange(this.props.opening_times.set(day, dayOpeningTimes.map((currentOpeningTime) => {
//       if(openingTime.get('id') == currentOpeningTime.get('id')) {
//
//       }
//     })))
//   }
//
//   onInputClosedChanged(event, openingTime, day) {
//     event.preventDefault();
//
//     openingTime = openingTime.set('close_time', event.target.value);
//   }
//
//
//   render() {
//     let days = Immutable.List();
//
//     let cols = this.props.cols;
//
//     for(let day=1; day<=7; day++) {
//       const openingTimesData = this.props.openingTimes.get(day);
//       const openingTimes = this.props.openingTimes.get(day).get('opening_times');
//       let columnData = Immutable.List();
//       for(let colIndex = 0; colIndex<cols; colIndex++) {
//         let colContent = null;
//         if(openingTimes.get(colIndex)) {
//           const openingTime = openingTimes.get(colIndex);
//           const parsedOpenTime = moment(openingTime.get('open_time'));
//           const parsedCloseTime = moment(openingTime.get('close_time'));
//
//           colContent = (
//             <div className='form-row px-1 m-1'>
//               <div className="col-6">
//                 <input maxLength='5' onChange={(event) => this.onInputOpenChanged(event, openingTime, day)} className='form-control form-control-sm' type='text' value={parsedOpenTime.format('HH:mm')} />
//               </div>
//               <div className="col-6">
//                 <input maxLength='5' onChange={(event) => this.onInputClosedChanged(event, openingTime, day)} className='form-control form-control-sm' type='text' value={parsedCloseTime.format('HH:mm')} />
//               </div>
//             </div>
//           )
//         }
//         else {
//           colContent = (
//             <div className='form-row px-1 m-1'>
//               <div className="col-6">
//                 <input maxLength='5' className='form-control form-control-sm' type='text' value={''} />
//               </div>
//               <div className="col-6">
//                 <input maxLength='5' className='form-control form-control-sm' type='text' value={''} />
//               </div>
//             </div>
//           )
//         }
//         columnData = columnData.push(
//           <div className='col px-1' key={colIndex}>
//             {colContent}
//           </div>
//         );
//       }
//
//       days = days.push(
//         <div key={day} className='row'>
//           <div className='col'>
//             <input className='mr-1' type='checkbox' checked={openingTimesData.get('enabled')} />
//
//             {DAY_MAPPING[day.toString()]}
//           </div>
//           {columnData}
//         </div>
//       );
//     }
//
//     return (
//       <div>
//         {days}
//         <div className='row'>
//           <button className='btn btn-success'>Neue Spalte</button>
//         </div>
//       </div>
//     )
//   }
// }
//
// class OpeningTimes extends React.Component  {
//
//   constructor(props) {
//     super(props);
//
//     const openingTimes = Immutable.fromJS(this.props.opening_times);
//
//     const inhouseOpeningTimes = openingTimes.filter((item) => item.get('source') == 'inhouse');
//     const deliverOpeningTimes = openingTimes.filter((item) => item.get('source') == 'delivery');
//     const bookingOpeningTimes = openingTimes.filter((item) => item.get('source') == 'booking');
//
//     let inhouseOpeningTimesCols = createOpeningTimesTable(inhouseOpeningTimes).map((v) => v.get('opening_times').count()).max();
//     let deliverOpeningTimesCols = createOpeningTimesTable(deliverOpeningTimes).map((v) => v.get('opening_times').count()).max();
//     let bookingOpeningTimesCols = createOpeningTimesTable(bookingOpeningTimes).map((v) => v.get('opening_times').count()).max();
//
//
//     if(inhouseOpeningTimesCols < 2) {
//       inhouseOpeningTimesCols = 2;
//     }
//
//     if(deliverOpeningTimesCols < 2) {
//       deliverOpeningTimesCols = 2;
//     }
//
//     if(bookingOpeningTimesCols < 2) {
//       bookingOpeningTimesCols = 2;
//     }
//
//     this.state = {
//       bookingOpeningTimesCols,
//       inhouseOpeningTimesCols,
//       deliverOpeningTimesCols
//     }
//   }
//
//   onOpeningTimesChanged(newOpeningTimes) {
//     this.setState({
//       opening_times: newOpeningTimes.toJS()
//     });
//   }
//
//   render() {
//     const openingTimes = Immutable.fromJS(this.props.opening_times);
//
//     const inhouseOpeningTimes = openingTimes.filter((item) => item.get('source') == 'inhouse');
//     const deliverOpeningTimes = openingTimes.filter((item) => item.get('source') == 'delivery');
//     const bookingOpeningTimes = openingTimes.filter((item) => item.get('source') == 'booking');
//
//     return (
//       <div>
//         <OpeningTimesTabs
//           opening_times_delivery_label={this.props.opening_times_delivery_label}
//           opening_times_restaurant_label={this.props.opening_times_restaurant_label}
//           />
//
//         <div className="tab-content">
//           <div role="tabpanel" className="tab-pane active" id="inhouse">
//             <OpeningTimesTable onChange={this.onOpeningTimesChanged.bind(this)} cols={this.state.inhouseOpeningTimesCols} openingTimes={createOpeningTimesTable(inhouseOpeningTimes)} />
//       		</div>
//           <div role="tabpanel" className="tab-pane" id="delivery">
//             <OpeningTimesTable onChange={this.onOpeningTimesChanged.bind(this)} cols={this.state.deliverOpeningTimesCols} openingTimes={createOpeningTimesTable(deliverOpeningTimes)} />
//       		</div>
//           <div role="tabpanel" className="tab-pane" id="booking">
//             <OpeningTimesTable onChange={this.onOpeningTimesChanged.bind(this)} cols={this.state.bookingOpeningTimesCols} openingTimes={createOpeningTimesTable(bookingOpeningTimes)} />
//       		</div>
//         </div>
//
//         <hr />
//       </div>
//     );
//   }
// }
//
// window.OpeningTimes = OpeningTimes;

import $ from 'jquery';
import moment from 'moment';

$(document).ready(() => {
  $('.opening-times-open-day-button').click(function () {
    const inputs = $(this).parent().parent().find('.form-control');

    inputs.each((index, input) => {
      const $input = $(input);
      index = parseInt(index / 2);

      if ($input.hasClass('opentime')) {
        if (window.openingTimesData[index]) {
          const date = moment(window.openingTimesData[index].close_time);

          const text = date.format('HH:mm');
          $input.val(text);
        }
      } else {
        if (window.openingTimesData[index]) {
          const date = moment(window.openingTimesData[index].close_time);

          const text = date.format('HH:mm');

          $input.val(text);
          console.log(text);
        }
      }
    });

    $(this).hide();
  });
});
