import $ from 'jquery';
import React from 'react';
import Immutable from 'immutable';
import Slider, {Range} from 'rc-slider';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';

import '@interactjs/actions/drag';
import interact from '@interactjs/interact';

import RoomTable from './RoomTable';
import TableForm from './TableForm';
import safeCredentials from '../vendor/fetchHelper';

function keyIn(...keys) {
  var keySet = Immutable.Set(keys);
  return function (v, k) {
    return keySet.has(k);
  };
}

export default class RoomEditor extends React.Component {
  constructor(props) {
    super(props);

    const room = this.props.rooms[0];

    const x_factor = this.props.width / room.sketch_width;
    const y_factor = this.props.height / room.sketch_height;

    let factor = 0;
    if (x_factor < y_factor) {
      factor = x_factor;
    } else {
      factor = y_factor;
    }

    const bg_width = room.sketch_width * factor;
    const bg_height = room.sketch_height * factor;

    this.state = {
      prevTableStates: Immutable.List(),
      room: Immutable.fromJS(room),
      rooms: Immutable.fromJS(this.props.rooms),
      tableTypes: Immutable.fromJS(this.props.tableTypes),
      tables: Immutable.fromJS(this.props.rooms[0].tbles),
      askTableName: false,
      selectedTable: null,
      prevStateIndex: 0,
      graphic: this.props.graphic,
      pixelfactor: factor,
      width: bg_width,
      height: bg_height,
    };
  }

  componentDidMount() {
    const nextPrevTableStates = this.state.prevTableStates.push(
      this.state.tables,
    );
    this.setState({
      prevTableStates: nextPrevTableStates,
      prevStateIndex: nextPrevTableStates.count() + 1,
    });

    let snapOptions = {
      // targets: [
      //   interact.createSnapGrid({ x: 3, y: 3 })
      // ],
      range: Infinity,
      relativePoints: [{x: 0, y: 0}],
    };

    interact('.table')
      .draggable({
        // snap: snapOptions,
        inertia: true,
        // modifiers: [
        //   interact.modifiers.snap({
        //     range: Infinity,
        //     relativePoints: [ { x: 0, y: 0 } ]
        //   })
        // ],
        modifiers: [
          // interact.modifiers.restrict({
          //   restriction: 'parent', //this.grid,
          //   elementRect: {top: 0, left: 0, bottom: 1, right: 1},
          //   // endOnly: true
          // }),
        ],
      })
      .on('dragmove', (event) => {
        const target = event.target;
        const id = target.getAttribute('data-id');
        const table = this.state.tables.find((table) => table.get('id') == id);

        if (table == null) {
          return;
        }

        // keep the dragged position in the data-x/data-y attributes
        const x = (table.get('locx') || 0) + event.dx / this.state.pixelfactor;
        const y = (table.get('locy') || 0) + event.dy / this.state.pixelfactor;

        const newTable = table.set('locx', x).set('locy', y);
        const newTables = this.state.tables.map((table) => {
          if (table.get('id') == id) {
            return newTable;
          } else {
            return table;
          }
        });

        this.setState({
          tables: newTables,
          prevTableStates: this.state.prevTableStates.push(newTables),
          prevStateIndex: this.state.prevStateIndex + 1,
        });
      });
  }

  onAddTable(e) {
    e.preventDefault();

    this.setState({
      askTableName: true,
    });
  }

  async onDeleteTable(event) {
    event.preventDefault();

    let response = await fetch(
      this.props.deleteTablePath,
      safeCredentials({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tble: {
            name: name,
            room_id: this.props.rooms[0].id,
            tble_type_id: this.props.tableTypes[0].id,
          },
        }),
      }),
    );

    // fetch(window.location.href + '/' + this.state.selectedTable + '.json', safeCredentials({
    //   method: 'DELETE',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // })).then((response) => {
    //   if(response.status != 204) {
    //     // handle error
    //     this.setState({
    //       errorMessage: 'Interner Fehler'
    //     });
    //   }
    //   else {
    //     const newTables = this.state.tables.filter(table => this.state.selectedTable !== table.id);
    //     this.setState({
    //       tables: newTables,
    //       prevTableStates: Immutable.List([newTables]),
    //       prevStateIndex: 0
    //     })
    //   }
    // });
  }

  async onCreateTable(name) {
    let response = await fetch(
      this.props.newTablePath,
      safeCredentials({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tble: {
            name: name,
            room_id: this.props.rooms[0].id,
            tble_type_id: this.props.tableTypes[0].id,
          },
        }),
      }),
    );

    let data = await response.json();

    if (data.error_code == 0) {
      this.setState({
        tables: this.state.tables.push(Immutable.fromJS(data.table)),
      });
    }

    this.setState({
      askTableName: false,
    });
    // fetch(window.location.href + '.json', safeCredentials({
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     simple_table: {
    //       name: name
    //     }
    //   })
    // })).then((response) => {
    //   if(response.status == 422) {
    //     response.json().then((data) => {
    //       // handle error
    //       this.setState({
    //         errorMessage: data.join(' ')
    //       });
    //     });
    //   }
    //   else {
    //     response.json().then((data) => {
    //       const table = this.convertTable(Immutable.fromJS(data));
    //       const newTables = this.state.tables.push(table);
    //       this.setState({
    //         tables: newTables,
    //         prevTableStates: Immutable.List([newTables]),
    //         prevStateIndex: 0
    //       })
    //     });
    //   }
    //
    // })

    // this.setState({ askTableName: false });
  }

  onCancelTable() {
    this.setState({
      askTableName: false,
    });
  }

  onHideErrorMessages() {
    this.setState({
      errorMessage: null,
    });
  }

  onSaveTables(e) {
    e.preventDefault();
    const newTables = this.state.tables.map((item) =>
      item.filter(
        keyIn(
          'id',
          'name',
          'bookable',
          'orientation',
          'locx',
          'locy',
          'maxbookpeople',
          'minbookpeoples',
          'tble_type_id',
        ),
      ),
    );

    fetch(
      this.props.jx_update_path,
      safeCredentials({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tables: newTables.toJS(),
        }),
      }),
    );

    // const promises = this.state.tables.map((table) => {
    //   const relativeTable = this.unconvertTable(table);
    //   return fetch(window.location.href + '/' + table.get('id') + '.json', safeCredentials({
    //     method: 'PUT',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({
    //       simple_table: relativeTable.filter(
    //         keyIn('name', 'table_type', 'relative_x', 'relative_y',
    //               'relative_width', 'relative_height', 'persons', 'priority')
    //       ).toJS()
    //     })
    //   }));
    // });
    // Promise.all(promises.toJS()).then(data => {
    //   this.setState({
    //     showSaved: true
    //   });
    // });
  }

  onHideSave() {
    this.setState({
      showSaved: false,
    });
  }

  onTableClicked(table) {
    this.setState({
      selectedTable: table.id,
    });
  }

  onTableChanged(newTable) {
    const newTables = this.state.tables.map((table) => {
      if (table.get('id') == newTable.get('id')) {
        return newTable;
      } else {
        return table;
      }
    });
    const nextPrevTableStates = this.state.prevTableStates
      .slice(0, this.state.prevStateIndex)
      .push(newTables);

    this.setState({
      tables: newTables,
      prevTableStates: nextPrevTableStates,
      prevStateIndex: this.state.prevStateIndex + 1,
    });
  }

  onChangeHistory(value) {
    const nextPrevStateIndex = value;
    const nextTables = this.state.prevTableStates.get(nextPrevStateIndex);

    this.setState({
      prevStateIndex: nextPrevStateIndex,
      tables: nextTables,
    });
  }

  onDrop(files) {
    const file = files[0];
    const url =
      window.location.href.split('/').slice(0, -1).join('/') +
      '/room_background_graphics.json';
    let formData = new FormData();
    formData.append('room_background_graphic[image]', file);

    $.ajax({
      type: 'POST',
      url: url,
      cache: false,
      processData: false,
      contentType: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          'X-CSRF-Token',
          $('meta[name="csrf-token"]').attr('content'),
        );
      },
      data: formData,
    });

    // .then((response, status, xhr) => {
    //   if(xhr.status != 201) {
    //     // handle error
    //     this.setState({
    //       errorMessage: response.join(' ')
    //     });
    //   }
    //   else {
    //     this.setState({
    //       graphic: response
    //     });
    //   }
    // })
  }

  render() {
    let saveDialog = null;
    if (this.state.showSaved) {
      saveDialog = (
        <SweetAlert
          success
          title="Gespeichert"
          onConfirm={this.onHideSave.bind(this)}>
          Tische erfolgreich gespeichert.
        </SweetAlert>
      );
    }

    let errorDialog = null;
    if (this.state.errorMessage) {
      errorDialog = (
        <SweetAlert
          warning
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Fehler"
          onConfirm={this.onHideErrorMessages.bind(this)}>
          {this.state.errorMessage}
        </SweetAlert>
      );
    }

    const tables = this.state.tables.map((table) => (
      <RoomTable
        pixelfactor={this.state.pixelfactor}
        onTableClicked={this.onTableClicked.bind(this)}
        table={table.toJS()}
        {...table.toJS()}
        key={table.get('id')}
      />
    ));
    let dialog = null;
    if (this.state.askTableName) {
      dialog = (
        <SweetAlert
          input
          showCancel
          show={true}
          cancelBtnBsStyle="default"
          title="Tisch Name"
          placeHolder="1"
          cancelBtnText="Abbrechen"
          validationMsg="Bitte eingeben"
          onConfirm={this.onCreateTable.bind(this)}
          onCancel={this.onCancelTable.bind(this)}>
          Bitte Name eingeben (Zahl)
        </SweetAlert>
      );
    }

    const selectedTable = this.state.tables
      .filter((table) => this.state.selectedTable === table.get('id'))
      .first();

    let gridStyle = {
      border: '1px solid #cccccc',
      height: this.state.height,
      width: this.state.width,
      position: 'relative',
      backgroundImage:
        'url(/images/gen/uploads/' +
        encodeURI(this.state.room.get('sketchfile')) +
        ')',
      backgroundSize: 'cover',
    };

    let tableForm = <span>Tisch wählen</span>;
    if (selectedTable) {
      tableForm = (
        <TableForm
          tableTypes={this.props.tableTypes}
          table={selectedTable.toJS()}
          onDeleteTable={this.onDeleteTable.bind(this)}
          onTableChanged={this.onTableChanged.bind(this)}
        />
      );
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-12" style={{marginBottom: 5}}>
            {dialog}
            {saveDialog}
            {errorDialog}
            <div className="btn-group" role="group" aria-label="aktionen">
              <a
                href="#"
                onClick={this.onAddTable.bind(this)}
                className="btn btn-light">
                Neuer Tisch
              </a>
              <a
                href="#"
                onClick={this.onSaveTables.bind(this)}
                className="btn btn-success">
                Speichern
              </a>
            </div>
          </div>

          <div className="col-md-8">
            <div style={{width: this.state.width, height: this.state.height}}>
              <div
                ref={(grid) => {
                  this.grid = grid;
                }}
                style={gridStyle}>
                {tables}
              </div>
            </div>
          </div>

          <div className="col-md-4">{tableForm}</div>
          <div className="col-md-8">
            <h3>Historie</h3>
            <Slider
              value={this.state.prevStateIndex}
              onChange={this.onChangeHistory.bind(this)}
              step={1}
              max={this.state.prevTableStates.count() - 1}
              min={0}
              orientation="horizontal"
            />
          </div>
        </div>
      </div>
    );
  }
}

window.RoomEditor = RoomEditor;
