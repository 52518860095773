import React from 'react';
import $ from 'jquery';

export default function CouponForm({coupon, url, method}) {
  const [couponType, setCouponType] = React.useState(coupon.coupon_type);
  const [value1, setValue1] = React.useState(coupon.value1);
  const [value2, setValue2] = React.useState(coupon.value2);
  const [name, setName] = React.useState(coupon.name);
  const [description, setDescription] = React.useState(coupon.description);

  const csrf = $('meta[name="csrf-token"]').attr('content');

  return (
    <form method={method} action={url}>
      <input
        type="hidden"
        name="authenticity_token"
        value={csrf}
        autoComplete="off"
      />

      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          value={name}
          onChange={(evt) => setName(evt.target.value)}
          name="coupon[name]"
          className="form-control"
          id="name"
          placeholder="Name"
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Beschreibung</label>
        <textarea
          value={description}
          onChange={(evt) => setDescription(evt.target.value)}
          name="coupon[description]"
          className="form-control"
          id="description"
          placeholder="Beschreibung"
        />
      </div>

      <div className="form-group">
        <label htmlFor="exampleFormControlSelect2">Gutschein Art</label>
        <select
          name="coupon[couponType]"
          className="form-control"
          id="exampleFormControlSelect2"
          value={couponType}
          onChange={(evt) => setCouponType(evt.target.value)}>
          <option value="free_shipping">Gratis Lieferung</option>
          <option value="free_product">Gratis Produkt</option>
          <option value="discount">Rabatt auf Bestellung</option>
          <option value="item_discount">Rabatt auf bestimmten Artikel</option>
        </select>
      </div>
      {couponType === 'free_product' && (
        <div className="form-group">
          <label htmlFor="value1">SKU (mehrere durch Komma trennen)</label>
          <input
            value={value1}
            onChange={(evt) => setValue1(evt.target.value)}
            name="coupon[value1]"
            className="form-control"
            id="value1"
            placeholder="Artikelnummer"
          />
        </div>
      )}
      {couponType === 'discount' && (
        <div className="form-group">
          <label htmlFor="value1">Betrag</label>
          <input
            value={value1}
            onChange={(evt) => setValue1(evt.target.value)}
            name="coupon[value1]"
            className="form-control"
            id="value1"
            placeholder="Betrag"
          />
        </div>
      )}
      {couponType === 'item_discount' && (
        <>
          <div className="form-group">
            <label htmlFor="value1">SKU (mehrere durch Komma trennen)</label>
            <input
              value={value1}
              onChange={(evt) => setValue1(evt.target.value)}
              name="coupon[value1]"
              className="form-control"
              id="value1"
              placeholder="Betrag"
            />
          </div>
          <div className="form-group">
            <label htmlFor="value2">Betrag</label>
            <input
              value={value2}
              onChange={(evt) => setValue2(evt.target.value)}
              name="coupon[value2]"
              className="form-control"
              id="value2"
              placeholder="Betrag"
            />
          </div>
        </>
      )}
      <input
        type="submit"
        name="submit"
        className="btn btn-success"
        value="Speichern"
      />
    </form>
  );
}
