import React from 'react';

import DiscountCategory from './DiscountCategory';


export default class DiscountProducts extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      category: null
    };
  }

  onCategoryClicked(category) {
    this.setState({
      category: category
    })
  }

  onCategoryChanged(category) {
    const categories = this.props.discount.get('discount_categories').map((c) => {
      if(c.get('category_name') == category.get('category_name')) {
        return category;
      }
      else {
        return c;
      }
    });

    this.setState({
      category: category
    });

    this.props.onDiscountChanged(
      this.props.discount.set('discount_categories', categories)
    );
  }

  render() {
    var linkStyle = {
      cursor: 'pointer'
    };

    var categories = this.props.discount.get('discount_categories').map((category) => {
      return (
        <li key={category.get('category_name')} className='nav-item'>
          <a className='nav-link' onClick={this.onCategoryClicked.bind(this, category)} style={linkStyle}>
            { category.get('category_name') }
          </a>
        </li>
      );
    });

    var category = null;
    if(this.state.category) {
      category = <DiscountCategory discountCategory={this.state.category}
                                   productVersion={this.props.productVersion}
                                   onCategoryChanged={this.onCategoryChanged.bind(this)}/>;
    }

    return (
      <div className="row">
        <div className="col-md-4">
          <ul className="nav flex-column">
            {categories}
          </ul>
        </div>
        <div className='col-md-8'>
          {category}
        </div>
      </div>
    );

  }

}

window.DiscountProducts = DiscountProducts;
