import React from 'react';

export const Image = (props) => {
  const style = {margin: 10, border: ''};
  if (props.selected === props.image) {
    style.margin = 8;
    style.border = '6px solid red';
  }
  return (
    <div className={'col-md-4'}>
      <img
        style={style}
        onClick={() => {
          props.onClick(props.image);
        }}
        src={props.image}
        className="img-fluid"
      />

      <input
        style={{display: 'none'}}
        readOnly={true}
        type="radio"
        name="obs_image_item[url]"
        value={props.image}
        checked={props.selected === props.image}
      />
    </div>
  );
};

export default class ImageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected,
    };
  }

  onImageClick(image) {
    this.setState({
      selected: image,
    });
  }

  render() {
    const items = this.props.images.map((image) => (
      <Image
        selected={this.state.selected}
        key={image}
        image={image}
        onClick={this.onImageClick.bind(this)}
      />
    ));

    return <div className="row">{items}</div>;
  }
}
