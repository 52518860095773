import React from 'react';
import Dropdown from 'react-dropdown';
import Immutable from 'immutable';

export default class TableForm extends React.Component {

  onTableNameChanged(event) {
    this.props.onTableChanged(this.props.table.set('name', event.target.value));
  }

  onTablePersonsChanged(event) {
    this.props.onTableChanged(this.props.table.set('persons', event.target.value));
  }

  onTablePrioChanged(prio) {
    this.props.onTableChanged(this.props.table.set('priority', prio.value));
  }

  onTableTypeChanged(type) {
    const actualType = Immutable.fromJS(this.props.tableTypes).filter(t => t.get('id') == type.value).first();
    const table = Immutable.fromJS(this.props.table);
    this.props.onTableChanged(table.set('tble_type', actualType).set('tble_type_id', actualType.id));
  }

  render() {
    const personsValue = this.props.table.maxbookpeople;
    const minBookPeoplesValue = this.props.table.minbookpeoples;

    const priorities = [
      { value: '0', label: 'Kann nicht reserviert werden' },
      { value: '1', label: 'Sehr niedrig' },
      { value: '2', label: 'Niedrig' },
      { value: '3', label: 'Mittel' },
      { value: '4', label: 'Hoch' },
      { value: '5', label: 'Sehr hoch' }
    ];

    const tableTypes = this.props.tableTypes.map(type => {
      return { value: type.id, label: type.name };
    });

    const value = 0;

    const table_type = tableTypes.filter(item => item.value === this.props.table.tble_type.id)[0];

    return (
      <form className="form-horizontal">
        <div className="form-group">
          <label htmlFor="inputTableName" className="px-0 col-sm-2 control-label ">Name</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="inputTableName" placeholder="Name" onChange={this.onTableNameChanged.bind(this)} value={this.props.table.name || ''} />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="inputTablePersons" className="px-0 col-sm-2 control-label">Personen</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="inputTablePersons" placeholder="Personen" onChange={this.onTablePersonsChanged.bind(this)} value={personsValue || ''} />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="inputTablePersons" className="px-0 col-sm-2 control-label">Personen mind.</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="inputTablePersons" placeholder="Personen" onChange={this.onTablePersonsChanged.bind(this)} value={minBookPeoplesValue || ''} />
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-12'>
            <Dropdown options={priorities} onChange={this.onTablePrioChanged.bind(this)} value={value} placeholder="Prioritaet" />
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-12'>
            <Dropdown options={tableTypes} onChange={this.onTableTypeChanged.bind(this)} value={table_type} placeholder="Prioritaet" />
          </div>
        </div>
        <button disabled={this.props.table == null} className='btn btn-danger pull-right' onClick={this.props.onDeleteTable}>
          Löschen
        </button>
      </form>
    )
  }
}
