import React from 'react'

const formatFloat = function (f) {
  return f.toLocaleString('de-DE', { style: 'decimal' })
}

export default class DiscountSettings extends React.Component {
  onDiscountTypeChanged (e) {
    const newDiscount = this.props.discount.set('discountable_type', e.currentTarget.value)
    this.props.onDiscountChanged(newDiscount)
  }

  onNameChanged (e) {
    this.props.onDiscountChanged(
      this.props.discount.set('name', e.currentTarget.value)
    )
  }

  onRelativeActivated (e) {
    const newDiscount = this.props.discount.set('absolute_discount', false)
      .set('relative_discount', true)

    this.props.onDiscountChanged(newDiscount)
  }

  onAbsoluteActivated (e) {
    const newDiscount = this.props.discount.set('absolute_discount', true)
      .set('relative_discount', false)

    this.props.onDiscountChanged(newDiscount)
  }

  setDiscount (e) {
    const newValue = e.currentTarget.value
      .replace(',', '.')
      .replace(/[^0-9.]+/g, '')
      .replace(/(\.\d\d)\d+/g, '$1')
      .replace(/^\./g, '0.')

    const newDiscount = this.props.discount.set('discount', newValue)
    this.props.onDiscountChanged(newDiscount)
  }

  setFreeShipping (e) {
    const newDiscount = this.props.discount.set('free_shipping', e.target.checked)
    this.props.onDiscountChanged(newDiscount)
  }

  render () {
    const discount = this.props.discount

    return (
      <div className='row-fluid'>
        <div className='col-md-12'>
          <form className='form-horizontal'>
            <div className='form-group'>
              <div className='col-md-2'>
                <label className='control-label'>Name:</label>
              </div>
              <div className='col-md-8'>
                <input
                  type='text'
                  value={discount.get('name')}
                  onChange={this.onNameChanged.bind(this)}
                />
              </div>
            </div>
            <div className='form-group'>
              <div className='col-md-2'>
                <label className='control-label'>Rabatt auf:</label>
              </div>
              <div className='col-md-8'>
                <div className='switch-toggle switch well'>
                  <input
                    type='radio'
                    name='discount-type'
                    id='item'
                    value='Item'
                    onChange={this.onDiscountTypeChanged.bind(this)}
                    checked={discount.get('discountable_type') === 'Item'}
                  />
                  <label htmlFor='item'>Artikel</label>

                  <input
                    type='radio'
                    id='cart'
                    name='discount-type'
                    value='Cart'
                    onChange={this.onDiscountTypeChanged.bind(this)}
                    checked={discount.get('discountable_type') === 'Cart'}
                  />
                  <label htmlFor='cart'>Split</label>
                  <a className='btn btn-success' />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-md-2'>
                <label className='control-label'>Rabatt in:</label>
              </div>
              <div className='col-md-8'>
                <div className='switch-toggle switch-2 well'>
                  <input
                    type='radio'
                    id='absolute-discount'
                    name='discount-value'
                    value='Absolute'
                    onChange={this.onAbsoluteActivated.bind(this)}
                    checked={discount.get('absolute_discount')}
                  />
                  <label htmlFor='absolute-discount'>Euro</label>

                  <input
                    type='radio'
                    name='relative-discount'
                    id='percent'
                    value='Prozent'
                    onChange={this.onRelativeActivated.bind(this)}
                    checked={discount.get('relative_discount')}
                  />
                  <label htmlFor='percent'>Prozent</label>
                  <a className='btn btn-success' />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='col-md-2'>
                <label className='control-label' htmlFor='discount-amount'>Rabatthöhe:</label>
              </div>
              <div className='input-append col-md-8'>
                <input
                  type='text'
                  id='discount-amount'
                  onChange={this.setDiscount.bind(this)}
                  value={formatFloat(discount.get('discount'))}
                />
              </div>
            </div>
            <div className='form-group'>
              <div className='col-md-2'>
                <label className='control-label' htmlFor='discount-free-shipping'>gratis Lieferung:</label>
              </div>
              <div className='input-append col-md-8'>
                <input
                  type='checkbox'
                  id='discount-free-shipping'
                  onChange={this.setFreeShipping.bind(this)}
                  checked={discount.get('free_shipping')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
